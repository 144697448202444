import { configureStore } from '@reduxjs/toolkit';
import productSlice from '../slice/productSlice';
import AuthSlice from '../slice/authSlice';
import SingleProductSlice from '../slice/singleProductSlice';
import categorySlice from '../slice/categorySlice';



export const store = configureStore({
  reducer: {
    categories: categorySlice,
    products: productSlice,
    auth: AuthSlice,
    singleProduct: SingleProductSlice,
  },
});
