import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import TitleSection from '../elements/about/titleSection';
import ImageText from '../elements/about/ImageText';
import IconBox from '../elements/about/IconBox';

const About = () => {
    return (
        <React.Fragment>
            <InnerBanner title="About us"/>
            <TitleSection/>
            <div class="container"><div class="line"></div></div>
            <ImageText/>
            <IconBox/>
        </React.Fragment>
    );
}

export default About;
