// features/productsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../components/global/GlobalVariable';

export const fetchSingleProduct = createAsyncThunk(
    'products/fetchSingleProduct',
    async (slug) => {
        const response = await axios.get(`${API_URL}/products/${slug}`);
        return response.data;
    }
);

const SingleProductSlice = createSlice({
    name: 'product',
    initialState: {
        product: {data: {}},
        status: 'idle', // idle | loading | succeeded | failed
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSingleProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSingleProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.product = action.payload; // Save product details to the state
            })
            .addCase(fetchSingleProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default SingleProductSlice.reducer;
