import React from 'react';

const ImageText = () => {
    return (
        <React.Fragment>
            <section className="flat-spacing-23 flat-image-text-section">
            <div className="container">
                <div className="tf-grid-layout md-col-2 tf-img-with-text style-4">
                    <div className="tf-image-wrap">
                        <img className="lazyload w-100" data-src="assets/images/collections/collection-69.jpg" src="assets/images/collections/collection-69.jpg" alt="collection-img"/>
                    </div>
                    <div className="tf-content-wrap px-0 d-flex justify-content-center w-100">
                        <div>
                            <div className="heading">Established - 2014</div>
                            <div className="text">
                            Established in 2014, Man Training Supplies was founded by John Doe, a visionary with a deep understanding of the needs of professionals in demanding industries. With a passion for providing high-quality equipment and attire for hotel chefs, medical personnel, and construction workers, John recognized a gap in the market for a store that specialized in durable, industry-specific supplies. He opened the first store in a small industrial hub, and it quickly gained a reputation for reliability and excellence, becoming a trusted source for professionals seeking top-tier products that stand the test of time.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="flat-spacing-15">
            <div className="container">
                <div className="tf-grid-layout md-col-2 tf-img-with-text style-4">
                    <div className="tf-content-wrap px-0 d-flex justify-content-center w-100">
                        <div>
                            <div className="heading">Our Mission</div>
                                <div className="text">
                                    Our mission is to provide top-quality, reliable equipment and supplies for professionals in the hotel, medical, and construction industries. <br className="d-xl-block d-none"/>
                                    We aim to empower individuals with the tools they need to perform their jobs safely and efficiently. <br className="d-xl-block d-none"/>
                                    At Man Training Supplies, we believe in offering durable, cost-effective solutions that cater to the unique demands of these essential fields. <br className="d-xl-block d-none"/>
                                    Excellence, reliability, and customer satisfaction are at the core of everything we do.
                                </div>

                        </div>
                    </div>
                    <div className="grid-img-group">
                        <div className="tf-image-wrap box-img item-1">
                            <div className="img-style">
                                <img className="lazyload" src="assets/images/collections/mission-1.jpg" data-src="assets/images/collections/mission-1.jpg" alt="img-slider"/>
                            </div>
                        </div>
                        <div className="tf-image-wrap box-img item-2">
                            <div className="img-style">
                                <img className="lazyload" src="assets/images/collections/mission.jpg" data-src="assets/images/collections/mission.jpg" alt="img-slider"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </React.Fragment>
    );
}

export default ImageText;
