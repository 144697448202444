import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../components/global/GlobalVariable';

// Async thunk to fetch categories
export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async () => {
    const response = await axios.get(`${API_URL}/categories`);
    return response.data; // Return the data directly
  }
);

// Category slice
const categorySlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [], // Initialize as an empty array
    loading: false,
    error: null,
    search: '', // State for the search term
    product_list: false, // Boolean to show/hide product list
  },
  reducers: {
    setSearch(state, action) {
      state.search = action.payload; // Update the search term
    },
    toggleProductList(state) {
      state.product_list = !state.product_list; // Toggle product list visibility
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true; // Set loading state
        state.error = null; // Reset error state
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.loading = false; // Set loading to false
        state.categories = payload; // Use 'categories' instead of 'data'
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false; // Set loading to false
        state.error = action.payload || action.error.message; // Assign the error message
      });
  },
});

// Export actions and reducer
export const { setSearch, toggleProductList } = categorySlice.actions;
export default categorySlice.reducer;
