import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Form from '../elements/contact/Form';

const Contact = () => {

    return (
        <React.Fragment>
            <InnerBanner title="Contact us"/>
            <section className='flat-spacing-9'>
                <Container>
                    <div className="tf-grid-layout gap-0 lg-col-2">
                        <div className="w-100">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d317859.6089702069!2d-0.075949!3d51.508112!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760349331f38dd%3A0xa8bf49dde1d56467!2sTower%20of%20London!5e0!3m2!1sen!2sus!4v1719221598456!5m2!1sen!2sus" width="100%" height="894" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="tf-content-left has-mt">
                            <div className="sticky-top">
                                <h5 className="mb_20">Visit Our Store</h5>
                                <div className="mb_20">
                                    <p className="mb_15"><strong>Address</strong></p>
                                    <p>66 Mott St, New York, New York, Zip Code: 10006, AS</p>
                                </div>
                                <div className="mb_20">
                                    <p className="mb_15"><strong>Phone</strong></p>
                                    <p>(623) 934-2400</p>
                                </div>
                                <div className="mb_20">
                                    <p className="mb_15"><strong>Email</strong></p>
                                    <p>EComposer@example.com</p>
                                </div>
                                <div className="mb_36">
                                    <p className="mb_15"><strong>Open Time</strong></p>
                                    <p className="mb_15">Our store has re-opened for shopping, </p>
                                    <p>exchange Every day 11am to 7pm</p>
                                </div>
                                <div>
                                    <ul className="tf-social-icon d-flex gap-20 style-default">
                                        <li><NavLink to="https://www.facebook.com/" className="box-icon link round social-facebook border-line-black"><i className="icon fs-14 icon-fb"></i></NavLink></li>
                                        <li><NavLink to="https://x.com/" className="box-icon link round social-twiter border-line-black"><i className="icon fs-12 icon-Icon-x"></i></NavLink></li>
                                        <li><NavLink to="https://www.instagram.com/" className="box-icon link round social-instagram border-line-black"><i className="icon fs-14 icon-instagram"></i></NavLink></li>
                                        <li><NavLink to="https://in.pinterest.com/" className="box-icon link round social-pinterest border-line-black"><i className="icon fs-14 icon-pinterest-1"></i></NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            {/* Form  */}
            <Form/>
        </React.Fragment>
    );
}

export default Contact;
