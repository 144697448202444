import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { loginUser } from '../../redux/slice/authSlice';

export default function LoginPage() {

    const { register, handleSubmit, formState: { errors, isSubmitting }} = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error } = useSelector((state) => state.auth);

    const onSubmit = (data) => {
        dispatch(loginUser(data)).then((response) => {
            console.log({response});
            if (response.payload?.token) {
                // Save the token to localStorage or sessionStorage
                localStorage.setItem('authToken', response.payload.token);
                Swal.fire({
                    title: 'Success', 
                    icon: 'success',
                    text: 'Login Successful!',
                    confirmButtonText: 'OK',
                });
                // Redirect to a home  or other page after login
                navigate('/'); // Change to your desired path
            } else {
                // Handle error case if needed
                Swal.fire('Error', response.payload.errorMsg || 'Login failed.', 'error');
            }
        });
    };

 

    return (
        <React.Fragment>
            <InnerBanner title="Login"/>

            <section className="flat-spacing-10">
                <div className="container">
                    <div className="tf-grid-layout lg-col-2 tf-login-wrap">
                        <div className="tf-login-form">
                            <div id="login">
                                <h5 className="mb_36">Log in</h5>
                                <div>
                                    <form  onSubmit={handleSubmit(onSubmit)} >
                                        <div className="tf-field style-1 mb_15">
                                            <input
                                                className="tf-field-input tf-input"
                                                placeholder=""
                                                type="email"
                                                id="property3"
                                                name="email"
                                                {...register('email', { required: "Email is required" })}
                                            />
                                            <label className="tf-field-label fw-4 text_black-2" for="property3">Email *</label>
                                            {errors.email && <span className="text-danger">{ errors.email.message }</span>}
                                        </div>

                                        <div className="tf-field style-1 mb_30">
                                            <input
                                                className="tf-field-input tf-input"
                                                placeholder=""
                                                type="password"
                                                id="property4"
                                                name="password"
                                                {...register('password', { required: "Password is required" })}
                                            />
                                            <label className="tf-field-label fw-4 text_black-2" for="property4">Password *</label>
                                            {errors.password && <span className="text-danger">{ errors.password.message }</span>}
                                        </div>
                                        <div className="mb_20">
                                            <NavLink to="/forgot" className="tf-btn btn-line">Forgot your password?</NavLink>
                                        </div>
                                        <div className="">
                                            <button
                                                type="submit"
                                                className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center"
                                                disabled={isSubmitting || loading}
                                            >
                                                { loading ? 'Logging in...' : 'Log in'}
                                            </button>
                                            {error && <p className="error">{error}</p>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="tf-login-content">
                            <h5 className="mb_36">I'm new here</h5>
                            <p className="mb_20">Sign up for early Sale access plus tailored new arrivals, trends and promotions. To opt out, click unsubscribe in our emails.</p>
                            <NavLink to="/register" className="tf-btn btn-line">Register<i className="icon icon-arrow1-top-left"></i></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
  )
}
