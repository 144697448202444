import React from 'react';
import { Container } from 'react-bootstrap';
import ReactOwlCarousel from 'react-owl-carousel';

const Testimonial = () => {

    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 3, // Show 2 cards at 500px and up
        },
        1000: {
            items: 4, // Show 3 cards at 1000px and up
        },
    };
    return (
        <section className="">
            <div className="container-full">
                <div className="bg_blue-4 radius-100 radius-10 rounded-top-0 flat-spacing-21">
                    <div className="flat-title wow fadeInUp" data-wow-delay="0s">
                        <span className="title fw-6">What our customers are saying</span>
                    </div>
                    <Container>
                        <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} autoplayHoverPause={true} margin={10} nav={false} dots={false} autoplayTimeout={2000} autoplay={true} loop={false}>
                                    <div className="swiper-slide">
                                        <div className="testimonial-item style-box wow fadeInUp" data-wow-delay="0s">
                                            <div className="author">
                                                <div className="name">Loretta</div>
                                            </div>
                                            <div className="rating color-black">
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                            </div>
                                            <div className="heading">A minimalist dream</div>
                                            <div className="text">
                                                Exceptional quality at a fraction of the price you would pay for big brands. Very elegant, discreet and beautiful materials.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-item style-box wow fadeInUp" data-wow-delay=".1s">
                                            <div className="author">
                                                <div className="name">Sandra</div>
                                            </div>
                                            <div className="rating color-black">
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                            </div>
                                            <div className="heading">Perfect meals for Mums.</div>
                                            <div className="text">
                                                Meals are delicious and great selection to choose from. I like the large portion option & info showing nutritional value.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-item style-box wow fadeInUp" data-wow-delay=".2s">
                                            <div className="author">
                                                <div className="name">Loretta</div>
                                            </div>
                                            <div className="rating color-black">
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                            </div>
                                            <div className="heading">A minimalist dream</div>
                                            <div className="text">
                                                Exceptional quality at a fraction of the price you would pay for big brands. Very elegant, discreet and beautiful materials.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-item style-box wow fadeInUp" data-wow-delay=".3s">
                                            <div className="author">
                                                <div className="name">Sandra</div>
                                            </div>
                                            <div className="rating color-black">
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                            </div>
                                            <div className="heading">Perfect meals for Mums.</div>
                                            <div className="text">
                                                Meals are delicious and great selection to choose from. I like the large portion option & info showing nutritional value.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-item style-box wow fadeInUp" data-wow-delay=".4s">
                                            <div className="author">
                                                <div className="name">Loretta</div>
                                            </div>
                                            <div className="rating color-black">
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                                <i className="icon-start"></i>
                                            </div>
                                            <div className="heading">A minimalist dream</div>
                                            <div className="text">
                                                Exceptional quality at a fraction of the price you would pay for big brands. Very elegant, discreet and beautiful materials.
                                            </div>
                                        </div>
                                    </div>
                        </ReactOwlCarousel>
                        
                    </Container>
                </div>
            </div>
        </section>

    );
}

export default Testimonial;
