import React from 'react';
import { Container } from 'react-bootstrap';

const Form = () => {


    return (
        <section className="bg_grey-7 flat-spacing-9">
                <Container>
                    <div className="flat-title">
                        <span className="title">Get in Touch</span>
                        <p className="sub-title text_black-2">If you’ve got great products your making or looking to work with us then drop us a line.</p>
                    </div>
                    <div>
                        <form className="mw-705 mx-auto text-center form-contact" id="contactform" method="post">
                            <div className="d-flex gap-15 mb_15">
                                <fieldset className="w-100">
                                    <input type="text" name="name" id="name" required placeholder="Name *"/>
                                </fieldset>
                                <fieldset className="w-100">
                                    <input type="email" name="email" id="email" required placeholder="Email *"/>
                                </fieldset>
                            </div>
                            <div className="mb_15">
                                <fieldset className="w-100">
                                    <input type="text" name="subject" id="subject" required placeholder="Subject *"/>
                                </fieldset>
                            </div>
                            <div className="mb_15">
                                <textarea placeholder="Message *" name="message" id="message" required cols="30" rows="10"></textarea>
                            </div>
                            <div className="send-wrap">
                                <button type="submit" className="tf-btn radius-3 btn-fill animate-hover-btn justify-content-center">Send</button>
                            </div>
                        </form>
                    </div>
                </Container>
            </section>
    );
}

export default Form;
