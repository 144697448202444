import React from 'react';
import { NavLink } from 'react-router-dom';

const Campaign = () => {

    return (
        <section className="flat-spacing-19">
            <div className="container">
                <div className="tf-grid-layout md-col-2 tf-img-with-text style-3">
                    <div className="tf-content-wrap">
                        <div>
                            <div className="heading wow fadeInUp fw-6" data-wow-delay="0s">Equip for Success <br/> with Man Training Supplies</div>
                            <p className="description wow fadeInUp" data-wow-delay="0s">At Man Training Supplies, we provide industry-leading tools <br />
                                to train the next generation of professionals. Whether you’re <br />
                                preparing for a career in construction, culinary arts, or healthcare, <br />
                                we have the high-quality equipment to ensure you're ready for <br />
                                real-world challenges. Equip yourself with confidence.
                            </p>
                            <NavLink to="/product" className="tf-btn btn-outline-dark radius-3 link justify-content-center wow fadeInUp" data-wow-delay="0s">Shop Collection</NavLink>
                            {/* <a href="shop-collection-list.html" className="tf-btn-loading tf-loading-default style-2"><span className="text">Shop Collection</span></a> */}
                        </div>
                    </div>
                    <div className="tf-image-wrap">
                        <img className="lazyload" data-src="assets/images/collections/white-chef.png" src="assets/images/collections/giftcard-6.jpg" alt="collection-img"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Campaign;
