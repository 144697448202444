import React from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import { NavLink } from 'react-router-dom';
import { ProductData } from '../../global/GlobalVariable';

const Categories = () => {

    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        450: {
            items: 2, // Show 1 card at 0px and up
        },
        500: {
            items: 3, // Show 2 cards at 500px and up
        },
        1000: {
            items: 4, // Show 3 cards at 1000px and up
        },
    };

    return (
        <section className="flat-spacing-11">
            <div className="container">
                <div className="position-relative">
                    <div className="flat-title flex-row justify-content-between px-0">
                        <span className="title fw-6 wow fadeInUp" data-wow-delay="0s">Popular Categories</span>
                    </div>
                    <div className="sw-pagination-wrapper">
                            <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} margin={10} dots={false}>
                            {
                                ProductData.map((data, index) => {
                                    // Calculate total product count for each category
                                    const totalProductCount = ProductData.reduce((count, category) => {
                                        return count + category.products.length;
                                    }, 0);
                                
                                    return (
                                        <div key={`cat-${index}`} className="swiper-slide" lazy="true">
                                            <div className="collection-item-v2 type-small hover-img">
                                                <div className="collection-inner">
                                                    <div className="collection-image img-style radius-10">
                                                        <img
                                                            className="lazyload"
                                                            data-src={`assets/images/collections/${data.image}`}
                                                            src={`assets/images/collections/${data.image}`}
                                                            alt="collection-img"
                                                        />
                                                    </div>
                                                    <div className="collection-content">
                                                        <div className="top">
                                                            <h5 className="heading fw-5">{data.category}</h5>
                                                            <p className="subheading">{totalProductCount} items</p> {/* Show total product count here */}
                                                        </div>
                                                        <div className="bottom">
                                                            <button className="tf-btn collection-title hover-icon btn-light">
                                                            <span>Shop now</span>
                                                                <i className="icon icon-arrow1-top-left"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            </ReactOwlCarousel>
                        
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Categories;
