import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer id="footer" className="footer md-pb-70">
        <div className="footer-wrap">
            <div className="footer-body">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 col-12">
                            <div className="footer-infor">
                                <div className="footer-logo">
                                    <NavLink to="/">
                                        <div className="footer-heading footer-heading-desktop">
                                            <img src="assets/images/logo/logo.png" alt=""/>
                                        </div>
                                        <div className="footer-heading footer-heading-moblie">
                                            <h6>Man Training Supplies</h6>
                                        </div>
                                    </NavLink>
                                </div>
                                <ul>
                                    <li>
                                        <p>Address: 4 Doynton parade, hoppers crossing, <br/> 3029, VIC</p>
                                    </li>
                                    <li>
                                        <p>Email: <NavLink to="mailto:info@mtsstore.com">info@mtsstore.com</NavLink></p>
                                    </li>
                                    <li>
                                        <p>Phone: <NavLink to="tel:(212) 555-1234">(212) 555-1234</NavLink></p>
                                    </li>
                                </ul>
                                <NavLink to="/contact" className="tf-btn btn-line">Get direction<i className="icon icon-arrow1-top-left"></i></NavLink>
                                <ul className="tf-social-icon d-flex gap-10">
                                    <li><NavLink to="#" className="box-icon w_34 round social-facebook social-line"><i className="icon fs-14 icon-fb"></i></NavLink></li>
                                    <li><NavLink to="#" className="box-icon w_34 round social-twiter social-line"><i className="icon fs-12 icon-Icon-x"></i></NavLink></li>
                                    <li><NavLink to="#" className="box-icon w_34 round social-instagram social-line"><i className="icon fs-14 icon-instagram"></i></NavLink></li>
                                    <li><NavLink to="#" className="box-icon w_34 round social-tiktok social-line"><i className="icon fs-14 icon-tiktok"></i></NavLink></li>
                                    <li><NavLink to="#" className="box-icon w_34 round social-pinterest social-line"><i className="icon fs-14 icon-pinterest-1"></i></NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 col-12 footer-col-block">
                            <div className="footer-heading footer-heading-desktop">
                                <h6>Help</h6>
                            </div>
                            <div className="footer-heading footer-heading-moblie">
                                <h6>Help</h6>
                            </div>
                            <ul className="footer-menu-list tf-collapse-content">
                                <li>
                                    <NavLink to="privacy-policy.html" className="footer-menu_item">Privacy Policy</NavLink>
                                </li>
                                <li> 
                                    <NavLink to="terms-conditions.html" className="footer-menu_item">Terms &amp; Conditions</NavLink>
                                </li>
                                <li> 
                                    <NavLink to="faq-1.html" className="footer-menu_item">FAQ’s</NavLink>
                                </li>
                                <li> 
                                    <NavLink to="wishlist.html" className="footer-menu_item">My Wishlist</NavLink>
                                </li>
                            </ul>
                        </div>
                        
                        <div className="col-xl-6 col-md-6 col-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22108.376837677904!2d77.99864999302856!3d27.170743844592913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39740d857c2f41d9%3A0x784aef38a9523b42!2sAgra%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1727282586089!5m2!1sen!2sin" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-bottom-wrap d-flex gap-20 flex-wrap justify-content-between align-items-center">
                                <div className="footer-menu_item">© 2024 MTS Store. All Rights Reserved</div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
}

export default Footer;
