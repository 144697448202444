import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { BlogList } from '../global/GlobalVariable';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

const BlogDetail = () => {

    const{ id } = useParams();

    const findBlog = BlogList.find(item => item.id === +id);

     // Get the index of the current blog in BlogList array
     const currentIndex = BlogList.findIndex((blogItem) => blogItem.id === +id);

     // Get previous and next blogs based on the index
     const prevBlog = currentIndex > 0 ? BlogList[currentIndex - 1] : null;
     const nextBlog = currentIndex < BlogList.length - 1 ? BlogList[currentIndex + 1] : null;



 
     if (!findBlog) {
         return <div>Blog not found</div>; // Fallback if blog is not found
     }


    return (
        <React.Fragment>
            <InnerBanner title="Blog Detail"/>
            
            {/* blog detail  */}
            <section className="blog-detail">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="blog-detail-main">
                                <div className="blog-detail-main-heading">
                                    <ul className="tags-lists justify-content-center">
                                        <li>
                                            <NavLink to="#" className="tags-item">{findBlog.label}</NavLink>
                                        </li>
                                    </ul>
                                    <div className="title">{findBlog.title}</div>
                                    <div className="meta">by <span>{findBlog.author_name ? findBlog.author_name : "admin"}</span> on <span>{findBlog.date}</span></div>
                                    <div className="image">
                                        <img className="lazyload" data-src={`assets/images/blog/${findBlog.image}`} src={`assets/images/blog/${findBlog.image}`} alt=""/>
                                    </div>
                                </div>
                                <blockquote>
                                    <div className="icon">
                                        <img src="assets/images/item/quote.svg" alt=""/>
                                    </div>
                                    <div className="text">{findBlog.description}</div>
                                </blockquote>
                                
                                <div className="bot d-flex justify-content-between flex-wrap align-items-center">
                                    <ul className="tags-lists">
                                        <li>
                                            <NavLink to="#" className="tags-item"><span>{findBlog.label}</span></NavLink>
                                        </li>
                                    </ul>
                                    <div className="d-flex align-items-center gap-20">
                                        <p>Share:</p>
                                        <ul className="tf-social-icon d-flex style-default">
                                            <li><NavLink to="#" className="box-icon round social-facebook border-line-black"><i className="icon fs-14 icon-fb"></i></NavLink></li>
                                            <li><NavLink to="#" className="box-icon round social-twiter border-line-black"><i className="icon fs-12 icon-Icon-x"></i></NavLink></li>
                                            <li><NavLink to="#" className="box-icon round social-instagram border-line-black"><i className="icon fs-14 icon-instagram"></i></NavLink></li>
                                            <li><NavLink to="#" className="box-icon round social-tiktok border-line-black"><i className="icon fs-14 icon-tiktok"></i></NavLink></li>
                                            <li><NavLink to="#" className="box-icon round social-pinterest border-line-black"><i className="icon fs-14 icon-pinterest-1"></i></NavLink></li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Dynamic Previous and Next navigation */}
                                <div className="tf-article-navigation">
                                    <div className="item position-relative d-flex w-100 prev">
                                    {
                                        prevBlog ? (
                                            <NavLink to={`/blog-detail/${prevBlog.id}`} className="icon">
                                                <i className="icon-arrow-left"></i>
                                            </NavLink>
                                        ) : (
                                            <span className="icon icon-disabled">
                                                <i className="icon-arrow-left"></i>
                                            </span>
                                        )
                                    }
                                    {
                                        prevBlog && (
                                            <div className="inner">
                                                <NavLink to={`/blog-detail/${prevBlog.id}`}>PREVIOUS</NavLink>
                                                <h6>
                                                    <NavLink to={`/blog-detail/${prevBlog.id}`}>{prevBlog.title}</NavLink>
                                                </h6>
                                            </div>
                                        )
                                    }
                                    </div>
                                    <div className="item position-relative d-flex w-100 justify-content-end next">
                                    {
                                        nextBlog ? (
                                            <div className="inner text-end">
                                                <NavLink to={`/blog-detail/${nextBlog.id}`}>NEXT</NavLink>
                                                <h6>
                                                    <NavLink to={`/blog-detail/${nextBlog.id}`}>{nextBlog.title}</NavLink>
                                                </h6>
                                            </div>
                                        ) : (
                                            <span className="inner text-end disabled">
                                                <span>NEXT</span>
                                            </span>
                                        )
                                    }
                                    {
                                        nextBlog ? (
                                            <NavLink to={`/blog-detail/${nextBlog.id}`} className="icon">
                                                <i className="icon-arrow-right"></i>
                                            </NavLink>
                                        ) : (
                                            <span className="icon icon-disabled">
                                                <i className="icon-arrow-right"></i>
                                            </span>
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Related article */}
            <section className="mb_30">
                <Container>
                    <div className="flat-title">
                        <h5 className="">Related Articles</h5>
                    </div>
                    <div className="hover-sw-nav view-default hover-sw-5">
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={30}
                            slidesPerView={3}
                            navigation={{
                                nextEl: '.nav-next-slider',
                                prevEl: '.nav-prev-slider',
                            }}
                            pagination={{ clickable: false }}
                            breakpoints={{
                                768: { slidesPerView: 3 },
                                576: { slidesPerView: 2 }
                            }}
                        >
                        {
                            BlogList.map((data,index) => (
                                <SwiperSlide key={`blog-list${index}`}>
                                        <div className="blog-article-item">
                                            <div className="article-thumb radius-10">
                                                <NavLink to={`/blog-detail/${data.id}`}>
                                                    <img className="lazyload" data-src={`assets/images/blog/${data.image}`} src={`assets/images/blog/${data.image}`} alt="img-blog"/>
                                                </NavLink>
                                                <div className="article-label">
                                                    <NavLink to="shop-collection-list" className="tf-btn style-2 btn-fill radius-3 animate-hover-btn">{data.label}</NavLink>
                                                </div>
                                            </div>
                                            <div className="article-content">
                                                <div className="article-title">
                                                    <NavLink to={`/blog-detail/${data.id}`} className="">{data.title}</NavLink>
                                                </div>
                                                <div className="article-btn">
                                                    <NavLink to={`/blog-detail/${data.id}`} className="tf-btn btn-line fw-6">Read more<i className="icon icon-arrow1-top-left"></i></NavLink>
                                                </div>
                                            </div>
                                        </div>

                                </SwiperSlide>
                            ))
                        }
                            
                            <div className="nav-sw nav-next-slider nav-next-recent box-icon w_46 round"><span className="icon icon-arrow-left"></span></div>
                            <div className="nav-sw nav-prev-slider nav-prev-recent box-icon w_46 round"><span className="icon icon-arrow-right"></span></div>
                        </Swiper>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default BlogDetail;
