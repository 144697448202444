import React from 'react';
import { NavLink } from 'react-router-dom';

const ShareSocial = () => {
    return (
        <div className="modal modalCentered fade modalDemo tf-product-modal modal-part-content" id="share_social">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="header">
                        <div className="demo-title">Share</div>
                        <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                    </div>
                    <div className="overflow-y-auto">
                        <ul className="tf-social-icon d-flex gap-10">
                            <li><NavLink to="#" className="box-icon social-facebook bg_line"><i className="icon icon-fb"></i></NavLink></li>
                            <li><NavLink to="#" className="box-icon social-twiter bg_line"><i className="icon icon-Icon-x"></i></NavLink></li>
                            <li><NavLink to="#" className="box-icon social-instagram bg_line"><i className="icon icon-instagram"></i></NavLink></li>
                            <li><NavLink to="#" className="box-icon social-tiktok bg_line"><i className="icon icon-tiktok"></i></NavLink></li>
                            <li><NavLink to="#" className="box-icon social-pinterest bg_line"><i className="icon icon-pinterest-1"></i></NavLink></li>
                        </ul>
                        <form className="form-share"  method="post" accept-charset="utf-8">
                            <fieldset>
                                <input type="text" value="https://themesflat.co/html/ecomus/" tabindex="0" aria-required="true"/>
                            </fieldset>
                            <div className="button-submit">
                                <button className="tf-btn btn-sm radius-3 btn-fill btn-icon animate-hover-btn" type="button">Copy</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShareSocial;
