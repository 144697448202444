import React from 'react';

const ChooseSection = () => {
    return (
        <React.Fragment>
            <section>
                <div className="container-full">
                    <div className="bg_green-1 radius-100 radius-10 rounded-bottom-0 flat-spacing-21">
                        <div className="flat-title wow fadeInUp" data-wow-delay="0s">
                            <span className="title fw-6">Why choose us?</span>
                        </div>
                        <div className="container flat-iconbox-v3">
                            <div className="wrap-carousel wrap-mobile wow fadeInUp" data-wow-delay="0s">
                                <div className="swiper tf-sw-mobile" data-preview="1" data-space="15">
                                    <div className="swiper-wrapper wrap-iconbox">
                                        <div className="swiper-slide">
                                            <div className="tf-icon-box text-center">
                                                <div className="icon">
                                                    <i className="fa-solid fa-screwdriver-wrench"></i>
                                                </div>
                                                <div className="content">
                                                    <div className="title fw-6">Construction Training Tools</div>
                                                    <p>We provide top-quality tools for training in construction, from safety gear to heavy-duty equipment. Equip your team with the tools they need to build a solid foundation.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tf-icon-box text-center">
                                                <div className="icon">
                                                    <i className="fa-solid fa-kitchen-set"></i>
                                                </div>
                                                <div className="content">
                                                    <div className="title fw-6">Chef Training Essentials</div>
                                                    <p>Our chef training supplies include premium-grade knives, utensils, and kitchen equipment to ensure your culinary students have the tools for success in any kitchen.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="tf-icon-box text-center">
                                                <div className="icon">
                                                <i className="fa-solid fa-stethoscope"></i>
                                                </div>
                                                <div className="content">
                                                    <div className="title fw-6">Nursing Training Equipment</div>
                                                    <p>From stethoscopes to practice mannequins, our nursing supplies are designed to provide hands-on learning experiences that help students master patient care.</p>
                                                </div>
                                            </div>
                                        </div>
                                
                                    </div>
                                </div>
                                <div className="sw-dots style-2 sw-pagination-mb justify-content-center"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default ChooseSection;
