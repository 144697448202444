import React, { lazy, Suspense, useEffect, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
// import Header from "./components/include/Header";
import Home from "./components/pages/Home";
import Preloader from "./components/include/Preloader";
import Footer from "./components/include/Footer";
import GoTop from "./components/include/GoTop";
import About from "./components/pages/About";
import CanvaSearch from "./components/elements/models/CanvaSearch";
import ShoppingCart from "./components/elements/models/ShoppingCart";
import Contact from "./components/pages/Contact";
import Blog from "./components/pages/Blog";
import BlogDetail from "./components/pages/BlogDetail";
import Product from "./components/pages/Product";
import { store } from "./redux/store/store";
import ShareSocial from "./components/elements/models/ShareSocial";
import AskQuestion from "./components/elements/models/AskQuestion";
import DelivaryReturn from "./components/elements/models/DelivaryReturn";
import LoginPage from "./components/pages/LoginPage";
import SignUpPage from "./components/pages/SignUpPage";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage";
import SingleProduct from "./components/pages/SingleProduct";

const Header = lazy(() => import("./components/include/Header"));

function Layout() {

  const [loading, setLoading] = useState(true);
 

  useEffect(() => {
      // Preloader will stay for 1 second (1000 milliseconds)
      const timer = setTimeout(() => {
          setLoading(false);
      }, 1000); // 1 second delay

      return () => clearTimeout(timer); // Cleanup timer when component unmounts
  }, []);


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  


  return (
    <React.Fragment>
      { loading ? (
        <Preloader /> // Show Preloader while loading is true
      ) : (
        <div id="wrapper"> 
          {/* Once loading is false, show the actual content */}
          <Suspense fallback={<Preloader/>}>
            <Header/>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/about" element={<About/>} />
              <Route path="/blog" element={<Blog/>} />
              <Route path="/blog-detail/:id" element={<BlogDetail/>} />
              <Route path="/contact" element={<Contact/>} />
              <Route path="/product" element={<Product/>} />
              <Route path="/login" element={<LoginPage/>} />
              <Route path="/forgot" element={<ForgotPasswordPage/>} />
              <Route path="/register" element={<SignUpPage/>} />
              <Route path="/single-product/:slug" element={<SingleProduct/>} />

            </Routes>
            <Footer/>
          </Suspense>
          <GoTop/>
          {/* All modals  */}

          <CanvaSearch/>
          <ShoppingCart/>
          <AskQuestion/>
          <DelivaryReturn/>
          <ShareSocial/>
        </div>
      )}
    </React.Fragment>
  );
}


export default function App(){
    return (
    <Provider store={store}>
      <HashRouter>
        <Layout/>
      </HashRouter>
    </Provider>
    )
}

