import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import { Col, Container, Row } from 'react-bootstrap';
import { BlogList } from '../global/GlobalVariable';
import { NavLink } from 'react-router-dom';


const Blog = () => {
    return (
        <React.Fragment>
            <InnerBanner title="Blog"/>
            
            {/* blog-grid-main */}
            <section className="blog-grid-main">
                <Container>
                    <Row>
                    {
                        BlogList.map((data, index) => (
                            <Col key={`blog-${index}`} xl="4" md="6" sm="12">
                                <div className="blog-article-item">
                                    <div className="article-thumb">
                                        <NavLink to={`/blog-detail/${data.id}`}>
                                            <img className="lazyload" data-src={`assets/images/blog/${data.image}`} src={`assets/images/blog/${data.image}`} alt="img-blog"/>
                                        </NavLink>
                                        <div className="article-label">
                                            <NavLink to={`/blog-detail/${data.id}`} className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">{data.label}</NavLink>
                                        </div>
                                    </div>
                                    <div className="article-content">
                                        <div className="article-title">
                                            <NavLink to={`/blog-detail/${data.id}`} className="">{data.title}</NavLink>
                                        </div>
                                        <div className="article-btn">
                                            <NavLink to={`/blog-detail/${data.id}`} className="tf-btn btn-line fw-6">Read more<i className="icon icon-arrow1-top-left"></i></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Blog;
