// redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../components/global/GlobalVariable';



// Fetch CSRF token from the backend
// const fetchCsrfToken = async () => {
//   await axios.get(`${API_URL}/sanctum/csrf-cookie`);
// };

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (userData, { rejectWithValue }) => {
    try {
      
      // Call this first to set the CSRF token cookie
      // await fetchCsrfToken();

      const response = await axios.post(`${API_URL}/signup`, userData,);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (credentials, { rejectWithValue }) => {
    try {

      // Call this first to set the CSRF token cookie
      // await fetchCsrfToken();

      const response = await axios.post(`${API_URL}/login`, credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { rejectWithValue }) => {
  try {
    await axios.post(`${API_URL}/log-out`);
    return true; // Indicate successful logout
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Async thunk to fetch user data
export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/user`);
      return response.data; // Return user data from the response
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const AuthSlice = createSlice({
  name: 'auth',
  initialState: { 
    user: null, 
    isLoggedIn: false, 
    loading: false, 
    error: null ,
    token: null, // You may want to store the token here
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(registerUser.pending, (state) => {
      state.loading = true;
    })
    .addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.user = action.payload;
    })
    .addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(loginUser.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user; // Adjust based on your payload structure
      state.token = action.payload.token; // Save token from payload
    })
    .addCase(logoutUser.fulfilled, (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null; // Remove token on logout
    })
    .addCase(fetchUserData.fulfilled, (state, action) => {
      state.user = action.payload; // Set the user data from the response
    })
    .addCase(fetchUserData.rejected, (state, action) => {
      state.error = action.payload; // Handle error if fetching user data fails
    });
  },
});

export default AuthSlice.reducer;