import React from 'react';
import InnerBanner from '../elements/InnerBanner';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';


export default function ForgotPasswordPage() {

    const { register, handleSubmit, formState: { errors, isSubmitting }} = useForm();
    
    

 

    return (
        <React.Fragment>
            <InnerBanner title="Reset Password"/>

            <section className="flat-spacing-10">
                <div className="container">
                    <div className="tf-grid-layout lg-col-2 tf-login-wrap">
                        <div className="tf-login-form">
                            <div id="login">
                                <h5 className="mb_36">Forgot Password</h5>
                                <div>
                                    <form onSubmit={handleSubmit} >
                                        <div className="tf-field style-1 mb_15">
                                            <input
                                                className="tf-field-input tf-input"
                                                placeholder=""
                                                type="email"
                                                id="property3"
                                                name="email"
                                                {...register('email', { required: "Email is required" })}
                                            />
                                            <label className="tf-field-label fw-4 text_black-2" for="property3">Email *</label>
                                            {errors.email && <span className="text-danger">{ errors.email.message }</span>}
                                        </div>

                                        <div className="mb_20">
                                            <NavLink to="/login" className="tf-btn btn-line">Cancel</NavLink>
                                        </div>
                                        <div className="">
                                            <button
                                                type="submit"
                                                className="tf-btn w-100 radius-3 btn-fill animate-hover-btn justify-content-center"
                                            >{ isSubmitting ? 'Submitting' : 'Submit' }</button>
                                         
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="tf-login-content">
                            <h5 className="mb_36">I'm new here</h5>
                            <p className="mb_20">Sign up for early Sale access plus tailored new arrivals, trends and promotions. To opt out, click unsubscribe in our emails.</p>
                            <NavLink to="/register" className="tf-btn btn-line">Register<i className="icon icon-arrow1-top-left"></i></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
  )
}
