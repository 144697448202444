import React from 'react';

const AskQuestion = () => {
    
    return (
        <div className="modal modalCentered fade modalDemo tf-product-modal modal-part-content" id="ask_question">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="header">
                        <div className="demo-title">Ask a question</div>
                        <span className="icon-close icon-close-popup" data-bs-dismiss="modal"></span>
                    </div>
                    <div className="overflow-y-auto">
                        <form className="">
                            <fieldset className="">
                                <label for="">Name *</label>
                                <input type="text" placeholder="" className="" name="text" tabindex="2" value=""
                                    aria-required="true" required=""/>
                            </fieldset>
                            <fieldset className="">
                                <label for="">Email *</label>
                                <input type="email" placeholder="" className="" name="text" tabindex="2" value=""
                                    aria-required="true" required=""/>
                            </fieldset>
                            <fieldset className="">
                                <label for="">Phone number</label>
                                <input type="number" placeholder="" className="" name="text" tabindex="2" value=""
                                    aria-required="true" required=""/>
                            </fieldset>
                            <fieldset className="">
                                <label for="">Message</label>
                                <textarea name="message" rows="4" placeholder="" className="" tabindex="2" aria-required="true"
                                    required=""></textarea>
                            </fieldset>
                            <button type="submit"
                                className="tf-btn w-100 btn-fill justify-content-center fw-6 fs-16 flex-grow-1 animate-hover-btn"><span>Send</span></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AskQuestion;
