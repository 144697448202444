import React from 'react';
import { NavLink } from 'react-router-dom';

const InnerBanner = ({title}) => {

    return (
        <div className="tf-page-title">
            <div className="container-full">
                <div className="row">
                    <div className="col-12">
                        <div className="heading text-center">{title}</div>
                        <ul className="breadcrumbs d-flex align-items-center justify-content-center">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <i className="icon-arrow-right"></i>
                            </li>
                            <li>
                                {title}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InnerBanner;
