import React from 'react';
import BreadCrumb from '../elements/product/BreadCrumb';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Productlist } from '../global/GlobalVariable';

const Product = () => {
    return (
        <React.Fragment>
            <BreadCrumb title="Products"/>


            <section className='flat-spacing-2'>
                <Container>
                    <div className="tf-shop-control grid-3 align-items-center">
                        <div className="tf-control-filter">
                            <NavLink to="#filterShop" data-bs-toggle="offcanvas" aria-controls="offcanvasLeft" className="tf-btn-filter"><span className="icon icon-filter"></span><span className="text">Filter</span></NavLink>
                        </div>

                       
                    </div>
                    <div  className="wrapper-control-shop">
                        <div className="meta-filter-shop"></div>
                        <div className="grid-layout wrapper-shop" data-grid="grid-4">
                                    
                            {
                                Productlist.map(data => (    
                                    <div key={`product-${data.id}`} className="card-product" data-price="18.95" data-size="m l xl" data-color="brown light-purple light-green">
                                        <div className="card-product-wrapper">
                                            <NavLink to={`/product-detail/${data.id}`} className="product-img">
                                                <img className="lazyload img-product" data-src={`assets/images/products/${data.image}`} src={`assets/images/products/${data.image}`} alt="image-product"/>
                                                <img className="lazyload img-hover" data-src={`assets/images/products/${data.image}`} src={`assets/images/products/${data.image}`} alt="image-product"/>
                                            </NavLink>
                                            <div className="list-product-btn">
                                                <NavLink to="#quick_add" data-bs-toggle="modal" className="box-icon bg_white quick-add tf-btn-loading">
                                                    <span className="icon icon-cart"></span>
                                                    <span className="tooltip">Add Cart</span>
                                                </NavLink>
                                                <NavLink to="#" className="box-icon bg_white wishlist btn-icon-action">
                                                    <span className="icon icon-heart"></span>
                                                    <span className="tooltip">Add to Wishlist</span>
                                                    <span className="icon icon-delete"></span>
                                                </NavLink>
                                                
                                            </div>
                                            
                                        </div>
                                        <div className="card-product-info">
                                            <NavLink to="product-detail" className="title link text-capitalize">{data.name}</NavLink>
                                            <span class="price">{data.desc}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Product;
