import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../../redux/slice/productSlice';
import { BASE_URL } from '../../global/GlobalVariable';

const Features = () => {
    const dispatch = useDispatch();
    const { products, loading, search, category, category_slug, best_product } = useSelector((state) => state.products);

    useEffect(() => {
        dispatch(fetchProducts({ search, category, category_slug, best_product }));
    }, [dispatch, search, category, category_slug, best_product]);
    
    console.log({ products });

    if (loading) {
        return <p>Loading...</p>;
    }

    // Check if products.data is present and an array
    const productData = products.data || []; // Default to empty array if undefined

    return (
        <React.Fragment>
            <section className='flat-spacing-9'>
                <Container>
                    <div className="flat-title wow fadeInUp" data-wow-delay="0s">
                        <span className="title fw-6">Most Popular Products</span>
                    </div>
                    <Swiper 
                        modules={[Navigation, Pagination, Autoplay]}
                        spaceBetween={30}
                        slidesPerView={4}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 3000 }}
                        speed={2000}
                        loop={true}
                        className="swiper tf-sw-slideshow"
                    >
                        {
                            productData.length > 0 ? (
                                productData.map((data, index) => (
                                    <SwiperSlide key={`product-${index}`}>
                                        <div className="card-product radius-10 style-giftcard">
                                            <div className="card-product-wrapper">
                                                <NavLink to={`/single-product/${data.slug}`} className="w-100">
                                                    <img 
                                                        className="lazyload img-product" 
                                                        data-src={`${BASE_URL}/${data.images}`} 
                                                        src={`${BASE_URL}/${data.images}`} 
                                                        alt="image-product" 
                                                    />
                                                </NavLink>
                                                <div className="list-product-btn">
                                                    <NavLink to="#" className="box-icon bg_white wishlist btn-icon-action">
                                                        <span className="tooltip">Add to Wishlist</span>
                                                        <span className="icon icon-heart"></span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="card-product-info">
                                                <NavLink to={`/single-product/${data.slug}`} className="title link fw-6 text-capitalize">{data.name}</NavLink>
                                                <span className="price"><span>{data.short_description}</span></span>
                                                <div className="tf-product-btns">
                                                    <NavLink to={`/single-product/${data.slug}`} className="fw-7 fs-12 w-100 d-flex justify-content-center text-capitalize">View Detail</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            ) : (
                                <p>No products available.</p> // Handle case where there are no products
                            )
                        }
                    </Swiper>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Features;
